<template>
  <v-card>
    <v-card-title>
      Encounter Object Dump
      - Index: {{ $store.getters['encounters/getIndexById']($route.query.id), }}
      <v-spacer></v-spacer>
      <btn
        label="Reset Encounter"
        color="secondary"
        :icon="icons.mdiUndoVariant"
        @click="resetEncounter"
      ></btn>
    </v-card-title>
    <v-card-text>
      <textarea
        ref="dumpEncounter"
        v-model="contentEncounter"
        style="height:350px;border:thin solid #777;border-radius:5px;"
        class="col-sm-12"
      ></textarea>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <btn
        label="Update Encounter"
        :icon="icons.mdiContentSave"
        color="error"
        @click="updateEncounter"
      ></btn>
      <v-spacer></v-spacer>
      <btn
        label="Clip Encounter"
        :icon="icons.mdiContentCopy"
        @click="copyEncounterToClipboard"
      ></btn>
    </v-card-actions>

    <v-divider></v-divider>

    <v-card-title>
      Patient Object Dump
      - Index: {{ $store.getters['patients/getIndexById'](encounter.patient_id), }}
      <v-spacer></v-spacer>
      <btn
        label="Reset Patient"
        color="secondary"
        :icon="icons.mdiUndoVariant"
        @click="resetPatient"
      ></btn>
    </v-card-title>
    <v-card-text>
      <textarea
        ref="dumpPatient"
        v-model="contentPatient"
        style="height:350px;border:thin solid #777;border-radius:5px;"
        class="col-sm-12"
      ></textarea>
    </v-card-text>
    <v-card-actions>
      <btn
        label="Back"
        color="secondary"
        :icon="icons.mdiArrowLeftCircle"
        @click="$store.dispatch('route/back')"
      ></btn>
      <v-spacer></v-spacer>
      <btn
        label="Update Patient"
        :icon="icons.mdiContentSave"
        color="error"
        @click="updatePatient"
      ></btn>
      <v-spacer></v-spacer>
      <btn
        label="Clip Patient"
        :icon="icons.mdiContentCopy"
        @click="copyPatientToClipboard"
      ></btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  mdiArrowLeftCircle, mdiContentCopy, mdiContentSave, mdiUndoVariant,
} from '@mdi/js'

export default {
  data() {
    return {
      encounter: this.$store.getters['encounters/getById'](this.$route.query.id),
      contentEncounter: null,
      contentPatient: null,
      icons: {
        mdiArrowLeftCircle, mdiContentCopy, mdiContentSave, mdiUndoVariant,
      },
    }
  },
  mounted() {
    this.contentEncounter = JSON.stringify(this.encounter, null, 2)
    this.contentPatient = JSON.stringify(this.$store.getters['patients/getById'](this.encounter.patient_id), null, 2)
  },
  methods: {
    copyEncounterToClipboard() {
      this.$refs.dumpEncounter.select()
      document.execCommand('copy')
    },
    copyPatientToClipboard() {
      this.$refs.dumpPatient.select()
      document.execCommand('copy')
    },
    updateEncounter() {
      let encounter = null
      try {
        encounter = JSON.parse(this.contentEncounter)
      } catch (e) {
        this.$store.dispatch('notify', { value: e, color: 'error' })
      }
      if (encounter) {
        this.$root.confirm({
          title: 'Update Encounter?',
          titleIconColor: 'error',
          body: 'Are you sure you wish to update this encounter?',
          confirm: 'Update',
          confirmColor: 'error',
        }).then(result => {
          if (result === true) {
            this.$store.commit('encounters/updateEncounter', { encounter })
            this.$store.dispatch('notify', { value: `Encounter ${encounter.id} updated.`, color: 'success' })
          }
        })
      }
    },
    updatePatient() {
      let patient = null
      try {
        patient = JSON.parse(this.contentPatient)
      } catch (e) {
        this.$store.dispatch('notify', { value: e, color: 'error' })
      }
      if (patient) {
        this.$root.confirm({
          title: 'Update Patient?',
          titleIconColor: 'error',
          body: 'Are you sure you wish to update this patient?',
          confirm: 'Update',
          confirmColor: 'error',
        }).then(result => {
          if (result === true) {
            this.$store.commit('patients/updatePatientSync', patient)
            this.$store.dispatch('notify', { value: `Patient ${patient.id} updated.`, color: 'success' })
          }
        })
      }
    },
    resetEncounter() {
      this.encounter = this.$store.getters['encounters/getById'](this.$route.query.id)
      this.contentEncounter = JSON.stringify(this.encounter, null, 2)
      this.$store.dispatch('notify', { value: `Encounter ${this.encounter.id} reset.`, color: 'success' })
    },
    resetPatient() {
      const patient = this.$store.getters['patients/getById'](this.encounter.patient_id)
      this.contentPatient = JSON.stringify(patient, null, 2)
      this.$store.dispatch('notify', { value: `Patient ${patient.id} reset.`, color: 'success' })
    },
  },
}
</script>
